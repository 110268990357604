import React, { useContext, useState } from 'react'

import { Field, FormContext } from '../containers/Form'



export const DefaultAllocatedBandwidth = ({
  initialValue,
  renderHtmlFields,
  ...props
}) => {
  const {
    handleChange
  } = useContext(FormContext)

  const [default_allocated_bandwidth_gbps, setdefault_allocated_bandwidth_gbps] = useState((initialValue || 1000) /1000)
  const [default_allocated_bandwidth_mpbs, setdefault_allocated_bandwidth_mbps] = useState(initialValue)
  const handleBwChange = ({ target: { value } }) => {
    var gbint = parseInt(value)
    var mbps_value = (gbint * 1000).toString()
    if (handleChange) {
      handleChange({ name: 'default_allocated_bandwidth', value: mbps_value});
    }
    setdefault_allocated_bandwidth_gbps(gbint)
    setdefault_allocated_bandwidth_mbps(mbps_value)
  }

  const renderInputFieldsForHTMLForm = () => {
    return (
      <>
        <input class="form-control hidden integer" type="number" initialvalue={default_allocated_bandwidth_mpbs} id="license_default_allocated_bandwidth" name="license[default_allocated_bandwidth]" value={default_allocated_bandwidth_mpbs}/>
        <br/>
      </>
    )
  }

  return (
    <>
      <input type='number'
        className='form-control'
        id='default_allocated_bandwidth_gbps'
        name='default_allocated_bandwidth_gbps'
        value={default_allocated_bandwidth_gbps}
        onChange={handleBwChange}
      />
      {renderHtmlFields && renderInputFieldsForHTMLForm()}
    </>
  )
}

export const AllocatedNGWAFBandwidths = ({
  initialValue,
  renderHtmlFields,
  ...props
}) => {

  return (
    <>
      <label class="control-label optional">Default Activation Bandwidth(in Gbps) From Token Requests</label>
      <DefaultAllocatedBandwidth initialValue={initialValue} renderHtmlFields={renderHtmlFields}/>
    </>
  )
}

export default AllocatedNGWAFBandwidths
