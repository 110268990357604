import React, { useState, useEffect } from 'react'
import { getMoment, getMomentStr } from '../helpers/moment'
import momenttz from 'moment-timezone'
import isEmpty from 'lodash/isEmpty'
import { GLMToastContainer, GLMToast } from '../helpers/toastr'
import log from 'loglevel'
import AuthorizedUsers from './AuthorizedUsers'
import ExpiresAt from './ExpiresAt'
import { Form, Field } from './Form'
import SkuSelect from './SkuSelect'
import AutoRenew from '../components/AutoRenew'
import { LicenseStepper, LicenseStep} from '../components/LicenseStepper'
import {FormSection} from '../components/Form'
import {
  AllocatedBandwidths,
  CheckInFrequency,
  CthunderAutoDeactivate,
  AllocatedNGWAFBandwidths,
  FlexPoolBandwidth
} from '../components/FlexPoolFields'
import SSLType from '../components/SSLType'
import {
  CustomerPoNumber,
  OraclePart,
  OracleLine,
  Reason,
  SfdcId,
  PartnerName,
  ServiceContractId
} from '../components/InternalFields'
import LicenseNotes from '../components/LicenseNotes'
import OrgSelect from '../components/OrgSelect'
import Quantity from '../components/Quantity'
import InputField from '../components/InputField'
import Processors from '../components/Processors'
import ManagedDevices from '../components/ManagedDevices'
import LegacyPartitions from '../components/LegacyPartitions'
import SalesOrderSelect from '../components/SalesOrderSelect'
import ShellAccess from '../components/ShellAccess'
import ShellRootAccess from '../components/ShellRootAccess'
import CThunderPartner from '../components/CThunderPartner'
import DiskEncryption from '../components/DiskEncryption'
import StartsAt from '../components/StartsAt'
import { Checkbox } from '../components/Input'
import RangeSlider from '../components/RangeSlider'
import RangeSliderLimited from '../components/RangeSliderLimited'
import PortSelector from '../components/PortSelector'
import Version from '../components/Version'
import get from 'lodash/get'
import formFieldConditions from '../helpers/formFieldConditions'
import HarmonyJson from './HarmonyJson'

if (process.env.NODE_ENV === 'development') {
  log.setLevel('debug')
}

momenttz.tz.setDefault('UTC')
const skusForPortsConfig = ['thunder', 'vthunder', 'bare_metal']

export const LicenseForm = ({ props, attributes, policies }) => {
  const formatDate = date => momenttz(date, 'DD/MM/YYYY')
  const [sku, setSku] = useState(attributes['sku'] || {})
  const [org, setOrg] = useState()
  const initialStartsDate = !attributes['starts_at'] ? formatDate(new Date(Date.now())) : getMomentStr(attributes['starts_at'])
  const [startsAt, setStartDate] = useState(initialStartsDate)
  const [bandwidthSlider, setBandwidthSlider] = useState({ isActive: false })
  const [flexpoolquanity, setFlexpoolQuanity] = useState({ isActive: attributes['number_of_appliances'] > 1 })
  const [vcpupoolcount, setVCPUPoolCount] = useState({ isActive: false })
  const [version_range, setVersionRange] = useState({ isActive: attributes['min_version'] != null })
  const [ssl_chipset_type_set, setSslChipsetType] = useState({ isActive: false })
  const [matchCoresAndMemory, setMatchCoresAndMemory] = useState(false)
  const [limitChassisQty, setLimitChassisQty] = useState(attributes['chassis_quantity'] != null)
  const [chassisQty, setChassisQty] = useState(attributes['chassis_quantity'])
  const [modularFlexpool, setModularFlexpool] = useState(attributes['modular_flexpool'])
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [licenseReason, setReason] = useState(props['license_reason'] || false)

  const id = attributes['id']
  const isNew = !id
  const isEdit = !isNew
  const action = isEdit ? 'Update' : 'Create'
  const title = isEdit ? `Update License` : `Create License`
  const method = isEdit ? 'PUT' : 'POST'
  const url = isEdit ? `/api/licenses/${id}/update_react.json` : '/api/licenses.json'
  const upgradeTrial = !!policies.upgradeTrial
  const canUpgradeInitial = isEmpty(sku) ? false : !isNew && !sku.trial

  const [canUpgrade, setCanUpgrade] = useState(canUpgradeInitial)

  log.debug('id', id)
  log.debug('url', `${method} ${url}`)
  log.debug('props', props)
  log.debug('attributes', attributes)
  log.debug('policies', policies)
  log.debug('sku', sku)

  // masssage data before pushing to the API to save/create
  const prepareFetchData = data => {
    let fixedData = data

    if (data['memory'] && data['memory'] < 1024) {
      fixedData['memory'] = data['memory'] * 1024
    }

    if (get(fixedData, 'order_attributes.a10_sales_order_id') === null) {
      delete fixedData['order_attributes']
    }

    fixedData['modular_flexpool'] = modularFlexpool

    return ({ license: fixedData })
  }

  const handleSuccess = ({ data }) => {
    log.debug('handleSuccess response', data)
    window.location = `/licenses/${id || data['id']}`
  }

  const handleError = () => {
    setFormSubmitting(false)
    window.scrollTo(0, 0)
  }

  const handleModularFlexpoolCheck = () => {
    setModularFlexpool(!modularFlexpool)
  }

  const handleStartChange = values => {
    setStartDate(values.value)
  }

  const handleReasonChange = (e) => {
    setReason(e.value)
  }

  const handleFlexpoolQuanityCheck = () => {
    setFlexpoolQuanity({ isActive: !flexpoolquanity.isActive })
  }

  const handleVCPUPoolProcessorCheck = () => {
    setVCPUPoolCount({ isActive: !vcpupoolcount.isActive })
  }

  const handleVersionRangeCheck = () => {
    setVersionRange({ isActive: !version_range.isActive })
  }

  const handleSslChipsetType = (isSet) => {
    setSslChipsetType({ isActive: isSet })
  }

  const getMatchChange = (e) => {
    setMatchCoresAndMemory(e.value)
  }

  const handleUpgradeChange = () => {
    setCanUpgrade(upgradeTrial)
    if (canUpgrade) {
      GLMToast('This license has been unlocked and you can now change the Product and Sku',
        {className: 'alert alert-notice'})
    }
  }

  const canHaveUnlimitedBw = (selectedSku) => {
    return (
      (selectedSku.modular && selectedSku.platform !== 'capacity_pool') ||
      selectedSku.unlimited_bandwidth
    )
  }

  const prepareConditionalFields = () => {
    const fc = new formFieldConditions({sku,
      vcpupoolcount,
      policies,
      limitChassisQty,
      modularFlexpool,
      bandwidthSlider,
      version_range,
      flexpoolquanity,
      matchCoresAndMemory,
      skusForPortsConfig,
      ssl_chipset_type_set})
    log.debug('conditions', fc.fields())
    return fc.fields()
  }

  const [fieldCond, setFieldCond] = useState(prepareConditionalFields())
  useEffect(() => {
    setTimeout(() => setFieldCond(prepareConditionalFields()))
  }, [sku, flexpoolquanity,
    vcpupoolcount, matchCoresAndMemory,
    limitChassisQty, modularFlexpool,
    bandwidthSlider, version_range, ssl_chipset_type_set])

  return (
    <Form
      title={title}
      url={url}
      method={method}
      attributes={attributes}
      prepareData={prepareFetchData}
      inputProps={props}
      onSuccess={handleSuccess}
      onError={handleError}
    >
      <LicenseStepper
        controlField={fieldCond}
        action={action}
        alertFn={GLMToast}
        formSubmitting={formSubmitting}
        setFormSubmitting={setFormSubmitting}>
        <LicenseStep title='Basic'>
          <FormSection>
            <Field name='name' />
            <Field name='description' />
            <Field name='parent_id' />
            <Field
              as={OrgSelect}
              name='organization_id'
              onOrgChange={setOrg}
            />
            <Field
              as={Reason}
              name='reason'
              getChange={handleReasonChange}
              disabled={'reason_locked' in attributes}
            />
            {licenseReason == 'customer_poc' &&
              <Field name='poc_customer_name' label='[POC] Customer' required={true} disabled={'reason_locked' in attributes} />
            }
            <Field
              as={SkuSelect}
              name='license_type'
              sku={sku}
              isNew={isNew}
              onSkuChange={setSku}
              canUpgrade={canUpgrade}
              upgradeTrial={upgradeTrial}
              onUpgradeChange={handleUpgradeChange}
              permitted
            />
            {fieldCond.modular_flexpool_checkbox &&
            <div className='row'>
              <div className='col-md-12 py1'>
                <Checkbox
                  label='Modular FlexPool'
                  checked={modularFlexpool || fieldCond.forceful_modular}
                  onChange={handleModularFlexpoolCheck}
                  disabled={fieldCond.forceful_modular}
                />
              </div>
            </div>
            }
            {!modularFlexpool && fieldCond.forceful_modular && handleModularFlexpoolCheck() }
            {fieldCond.tps &&
            <FormSection>
              <div className='row'>
                <div className='col-md-6 py1'>
                  <Field
                    as={Checkbox}
                    name='ingress_bw_limit'
                    label='Ingress BW Limit'
                  />
                </div>
                <div className='col-md-6 py1'>
                  <Field
                    as={Checkbox}
                    name='ingress_pps_limit'
                    label='Ingress PPS Limit'
                  />
                </div>
              </div>
            </FormSection>
            }

            {fieldCond.limit_vcpu_checkbox &&
            <div className='row'>
              <div className='col-md-12 py1'>
                <Checkbox
                  label='Limit vCPU Count'
                  onChange={handleVCPUPoolProcessorCheck}
                />
              </div>
            </div>
            }

            {fieldCond.number_of_vcpus &&
            <Field as={Processors} name='number_of_vcpus' />
            }

            {fieldCond.limit_acos_devices_checkbox &&
            <div className='row'>
              <div className='col-md-12 py1'>
                <Checkbox
                  label='Limit on Number of ACOS Devices Sharing this FlexPool License'
                  checked={flexpoolquanity.isActive}
                  onChange={handleFlexpoolQuanityCheck}
                />
              </div>
            </div>
            }
            {fieldCond.number_of_appliances &&
              <Field as={Quantity} name='number_of_appliances' />
            }
            {fieldCond.managed_devices &&
              <Field
                as={ManagedDevices}
                name='managed_devices'
                type={'number'}
                min={1}
              />
            }

            {fieldCond.number_of_partitions &&
              <Field as={LegacyPartitions} name='number_of_partitions' />
            }
            {fieldCond.starts_at &&
            <Field as={StartsAt} name='starts_at' onSelect={handleStartChange} initialValue={attributes['starts_at'] ? getMoment(attributes['starts_at']) : null} />
            }

            {(fieldCond.expires_at || (licenseReason != null && licenseReason != '')) &&
              <Field as={ExpiresAt} name='expires_at' isEdit={isEdit} sku={sku} startsAt={startsAt || initialStartsDate} canSetNever={policies.SOA_oracle_support} reason={licenseReason} initialValue={attributes['expires_at'] ? getMoment(attributes['expires_at']) : null} />
            }

            {fieldCond.auto_renew &&
            <Field as={AutoRenew} name='auto_renew' />
            }
            {fieldCond.enterprise && <FormSection>
              <Field
                as={Checkbox}
                name='enterprise'
                label='Enterprise'
              />
              <div>
                <small className='form-text text-muted font-weight-normal'>
                  {' '}License requests for new activation or bandwidth changes need approval from authorized license admin.
                  <br />
            WARNING: once this option is turned on, license requests from Thunders with a
            valid token will NO LONGER be automatically granted.
            Please try the workflow before using this in production.
                </small>
              </div>
            </FormSection>
            }
          </FormSection>
        </LicenseStep>
        <LicenseStep title='Modular Fields'>
          {fieldCond.chassis_qty_checkbox &&
          <div className='row'>
            <div className='col-md-12 py1'>
              <FormSection>
                <Field
                  as={Checkbox}
                  name='number_of_appliances'
                  label='Multiple Chassis License'
                  checked={limitChassisQty}
                  onChange={() => setLimitChassisQty(!limitChassisQty)}
                />
              </FormSection>
            </div>
          </div>
          }
          {fieldCond.license_chasis_quantity &&
          <div>
            <FormSection>
              <Field
                as={InputField}
                name='chassis_quantity'
                label='Chassis Quantity'
                id='license_chasis_quantity'
                type='number'
                min={2}
                max={25}
                customonchange={(v) => setChassisQty(v)}
              />
            </FormSection>
          </div>
          }

          {fieldCond.license_monitored_ipv4 &&
          <div>
            <FormSection>
              <Field
                as={InputField}
                name='monitored_ipv4'
                label='Monitored IPv4 Addresses'
                id='license_monitored_ipv4'
                type='number'
                min={1}
                max={4294967296}
              />
            </FormSection>
          </div>
          }

          {fieldCond.acos_version_checkbox &&
          <div>
            <FormSection>
              <Checkbox
                label='Limit ACOS Version'
                onChange={handleVersionRangeCheck}
                checked={version_range.isActive}
              />
            </FormSection>
          </div>
          }

          {fieldCond.version_min_max &&
          <div>
            <Field as={Version} name='min_version' id='license_min_version' min='5.2' max='98.98' label='Minimum' step='0.01' />
            <Field as={Version} name='max_version' id='license_max_version' min='5.21' max='98.99' label='Maximum' step='0.01' />
          </div>
          }

          {fieldCond.bandwidth_section &&
          <FormSection>
            {fieldCond.fp_bandwidth &&
            <Field
              as={FlexPoolBandwidth}
              name='bandwidth'
              initialValue={attributes['bandwidth']}
              showMbps={!(sku.platform == 'harmony' || sku.platform == 'ngwaf_pool')}
              chassisQty={limitChassisQty ? chassisQty : ''}
              showUnlimitedOption={canHaveUnlimitedBw(sku)}
            />
            }
            {fieldCond.allocated_bw &&
            <AllocatedBandwidths />
            }
            {fieldCond.allocated_bw_ngwaf &&
            <AllocatedNGWAFBandwidths initialValue={attributes['default_allocated_bandwidth']} />
            }
            {fieldCond.check_in_frequency &&
            <Field as={CheckInFrequency} name='check_in_frequency' />
            }
            {fieldCond.cthunder_auto_deactivate &&
            <Field as={CthunderAutoDeactivate} name='cthunder_auto_deactivate' />
            }
          </FormSection>
          }

          {
            fieldCond.pps &&
            <div>
              <FormSection>
                <Field as={RangeSlider} name='pps' title='Packets Per Second' unit='x 100k PPS' min={1} max={10000} />
              </FormSection>
            </div>
          }

          {fieldCond.cores_memory_checkbox &&
          <FormSection>
            <Field as={Checkbox} name='match_cores_and_memory' label={(!sku.flexpool || (sku.flexpool && modularFlexpool)) ? 'Set Fixed Activation Cores and Memory' : 'Set Fixed Activation Cores'} getChange={getMatchChange} />
            <br /><small className='form-text text-muted font-weight-normal'>
              {' '}By default, assigned cores and memory are set based on each activation's requested bandwidth.
            </small>
          </FormSection>
          }

          {
            fieldCond.core_and_ram &&
            <div>
              <FormSection>
                <Field as={RangeSlider} name='cpus' title='Maximum CPU Cores' unit='Cores' min={1} max={99} />
              </FormSection>
              {(!sku.flexpool || (sku.flexpool && modularFlexpool)) &&
              <FormSection>
                <Field as={RangeSlider} name='memory' title='Maximum RAM' unit='GB' min={2} max={1024} />
              </FormSection>
              }
            </div>
          }

          {
            fieldCond.ssl_chipset_type &&
            <div>
              <Field as={SSLType} name='ssl_chipset_type' onSslChange={handleSslChipsetType} />
            </div>
          }

          {fieldCond.ssl_chipset_count &&
          <FormSection>
            <Field as={RangeSlider} name='ssl_chipset_count' title='SSL Chipset Limit' unit='Chip' min={1} max={99} />
          </FormSection>
          }

          {fieldCond.spe_present &&
          <FormSection>
            <Field
              as={Checkbox}
              name='spe_present'
              label='SPE/Low-Latency Support'
            />
          </FormSection>
          }

          {fieldCond.hardware_blocking &&
          <FormSection>
            <Field
              as={Checkbox}
              name='hardware_blocking'
              label='Hardware Blocking'
            />
          </FormSection>
          }

          {fieldCond.port_selector &&
          <div className='row'>
            <div className='col-md-12 py1'>
              <PortSelector port_array={props.port_array} />
            </div>
          </div>
          }

          {fieldCond.subscriber_count &&
          <FormSection>
            <Field as={RangeSliderLimited} name='subscriber_count' title='Subscriber Count' unit='Subscribers' min={1000} step={1000}
              max={1000000} />
          </FormSection>
          }

        </LicenseStep>
        <LicenseStep title='Partner information'>
          {(fieldCond.c_thunder_partner || fieldCond.shell_access || fieldCond.shell_root_access || fieldCond.disk_encryption || fieldCond.partner_info)  && <FormSection>
            <Field as={SfdcId} name='sfdc_partner_id' />
            <Field as={PartnerName} name='partner_name' />
            <Field as={OraclePart} name='oracle_part' />
            <Field as={OracleLine} name='oracle_line' />
            <Field as={CustomerPoNumber} name='bill_to_purchase_order_id' />
            <Field as={ServiceContractId} name='service_contract_id' />
            <Field
              as={SalesOrderSelect}
              isRequired={policies.isSalesOrderRequired}
              name='order_attributes.a10_sales_order_id'
            />
            {fieldCond.shell_access &&
            <Field as={ShellAccess} name='shell_access' />
            }

            {fieldCond.shell_root_access &&
            <Field as={ShellRootAccess} name='shell_root_access' />
            }
            {fieldCond.c_thunder_partner &&
            <Field as={CThunderPartner} name='c_thunder_partner' />
            }
            {fieldCond.disk_encryption &&
            <Field as={DiskEncryption} name='disk_encryption' />
            }
          </FormSection>}
        </LicenseStep>

        <LicenseStep title='Authorized Users'>
          {!isEdit && <Field
            as={AuthorizedUsers}
            name='authorized_users'
            required={!(org && org.owner_id)}
          />
          }
        </LicenseStep>
        <LicenseStep title='Harmony Entitlements'>
          {fieldCond.hc_entitlements && <FormSection>
            <Field as={HarmonyJson}
              name='harmony_json'
              schema={props.hc_json_schema}
              initialVal={attributes['harmony_json']} />
          </FormSection>}
        </LicenseStep>
        <LicenseStep title='Notes'><FormSection>
          <Field
            as={LicenseNotes}
            name='license_notes_attributes'
            initialVal={props.license_notes}
            allowInternal={policies.writeInternal} />
        </FormSection></LicenseStep>
      </LicenseStepper>

      <GLMToastContainer />
    </Form>
  )
}

export default LicenseForm
