import React, { useState } from 'react'
import { getMoment, getMomentFmt, getMomentStr } from '../helpers/moment'
import momenttz from 'moment-timezone';
import { isEmpty } from 'lodash'
import { GLMToastContainer, GLMToast } from '../helpers/toastr'
import log from 'loglevel';
if (process.env.NODE_ENV === 'development') {
  log.setLevel('debug')
}

import AuthorizedUsers from './AuthorizedUsers'
import ExpiresAt from './ExpiresAt'
import { Form, Field } from './Form'
import SkuSelect from './SkuSelect'
import AutoRenew from '../components/AutoRenew'
import {
  FormActions,
  FormInfo,
  FormSection,
  FormSubmit,
  FormTitle
} from '../components/Form'
import {
  AllocatedBandwidths,
  CheckInFrequency,
  CthunderAutoDeactivate,
  FlexPoolBandwidth
} from '../components/FlexPoolFields'
import SSLType from '../components/SSLType'
import {
  CustomerPoNumber,
  OraclePart,
  OracleLine,
  Reason,
  SfdcId,
  PartnerName,
  ServiceContractId
} from '../components/InternalFields'
import LicenseNotes from '../components/LicenseNotes'
import OrgSelect from '../components/OrgSelect'
import Quantity from '../components/Quantity'
import InputField from '../components/InputField'
import Processors from '../components/Processors'
import SubscriberCount from '../components/SubscriberCount'
import ManagedDevices from '../components/ManagedDevices'
import LegacyPartitions from '../components/LegacyPartitions'
import SalesOrderSelect from '../components/SalesOrderSelect'
import ShellAccess from '../components/ShellAccess'
import ShellRootAccess from '../components/ShellRootAccess'
import CThunderPartner from '../components/CThunderPartner'
import StartsAt from '../components/StartsAt'
import { Checkbox } from '../components/Input'
import RangeSlider from '../components/RangeSlider'
import RangeSliderLimited from '../components/RangeSliderLimited'
import PortSelector from '../components/PortSelector'
import Version from '../components/Version'
import { get } from 'lodash'

momenttz.tz.setDefault("UTC");
const skusForPortsConfig = ['thunder', 'vthunder', 'bare_metal']

export const LicenseForm = ({ props, attributes, policies }) => {
  const formatDate = date => momenttz(date, 'DD/MM/YYYY')
  const [sku, setSku] = useState(attributes["sku"] || {})
  const [org, setOrg] = useState()
  const initialStartsDate = !attributes["starts_at"] ? formatDate(new Date(Date.now())) : getMomentStr(attributes["starts_at"])
  const [startsAt, setStartDate] = useState(initialStartsDate)
  const [bandwidthSlider, setBandwidthSlider] = useState({ isActive: false })
  const [flexpoolquanity, setFlexpoolQuanity] = useState({ isActive: attributes["number_of_appliances"] > 1 })
  const [vcpupoolcount, setVCPUPoolCount] = useState({ isActive: false })
  const [version_range, setVersionRange] = useState({ isActive: attributes["min_version"] != null })
  const [ssl_chipset_type_set, setSslChipsetType] = useState({ isActive: false })
  const [matchCoresAndMemory, setMatchCoresAndMemory] = useState(false)
  const [limitChassisQty, setLimitChassisQty] = useState(attributes["chassis_quantity"] != null)
  const [chassisQty, setChassisQty] = useState(attributes["chassis_quantity"])
  const [modularFlexpool, setModularFlexpool] = useState(attributes["modular_flexpool"])

  const id = attributes['id']
  const isNew = !id
  const isEdit = !isNew
  const action = isEdit ? 'Update' : 'Create'
  const title = isEdit ? `Update License` : `Create License`
  const method = isEdit ? 'PUT' : 'POST'
  const url = isEdit ? `/api/licenses/${id}/update_react.json` : '/api/licenses.json'
  const upgradeTrial = !!policies.upgradeTrial
  const canUpgradeInitial = isEmpty(sku) ? false : !isNew && !sku.trial

  const [canUpgrade, setCanUpgrade] = useState(canUpgradeInitial)

  log.debug('id', id)
  log.debug('url', `${method} ${url}`)
  log.debug('props', props)
  log.debug('attributes', attributes)
  log.debug('policies', policies)
  log.debug('sku', sku)

  // masssage data before pushing to the API to save/create
  const prepareFetchData = data => {
    let fixedData = data

    if (data["memory"] && data["memory"] < 1024) {
      fixedData["memory"] = data["memory"] * 1024
    }

    if(get(fixedData, 'order_attributes.a10_sales_order_id') === null) {
      delete fixedData["order_attributes"]
    }

    fixedData['modular_flexpool'] = modularFlexpool

    return ({ license: fixedData })
  }

  const handleSuccess = ({ data }) => {
    log.debug('handleSuccess response', data)
    window.location = `/licenses/${id || data["id"]}`
  }

  const handleError = () => {
    window.scrollTo(0,0)
  }

  const threatstopTrialSku = sku => {
    return (sku.trial && sku.platform === 'threatstop')
  }

  const handleModularFlexpoolCheck = () => {
    setModularFlexpool(!modularFlexpool)
  }

  const handleStartChange = values => {
    setStartDate(values.value)
  }

  const handleFlexpoolQuanityCheck = () => {
    setFlexpoolQuanity({ isActive: !flexpoolquanity.isActive })
  }

  const handleVCPUPoolProcessorCheck = () => {
    setVCPUPoolCount({ isActive: !vcpupoolcount.isActive })
  }

  const handleVersionRangeCheck = () => {
    setVersionRange({ isActive: !version_range.isActive })
  }

  const handleSslChipsetType = (isSet) => {
    setSslChipsetType({ isActive: isSet })
  }

  const getMatchChange = (e) => {
    setMatchCoresAndMemory(e.value)
  }

  const handleUpgradeChange = () => {
    setCanUpgrade(upgradeTrial)
    if (canUpgrade) {
      GLMToast('This license has been unlocked and you can now change the Product and Sku',
        {className: 'alert alert-notice'})
    }
  }

  const canHaveUnlimitedBw = (selectedSku) => {
    return (
      (selectedSku.modular && selectedSku.platform !== 'capacity_pool') ||
      selectedSku.unlimited_bandwidth
    )
  }

  return (
    <Form
      title={title}
      url={url}
      method={method}
      attributes={attributes}
      prepareData={prepareFetchData}
      inputProps={props}
      onSuccess={handleSuccess}
      onError={handleError}
    >
      <Field name='name' />
      <Field name='description' />
      <Field name='parent_id' />
      <Field
        as={OrgSelect}
        name='organization_id'
        onOrgChange={setOrg}
      />
      <Field
        as={SkuSelect}
        name='license_type'
        sku={sku}
        isNew={isNew}
        onSkuChange={setSku}
        canUpgrade={canUpgrade}
        upgradeTrial={upgradeTrial}
        onUpgradeChange={handleUpgradeChange}
        permitted
      />

      {sku.flexpool && !sku.trial &&
        <div className='row'>
          <div className='col-md-12 py1'>
            <Checkbox
              label='Modular FlexPool'
              checked={modularFlexpool}
              onChange={handleModularFlexpoolCheck}
            />
          </div>
        </div>
      }

      {sku.product === 'TPS' &&
        <FormSection>
          <div className='row'>
            <div className='col-md-6 py1'>
              <Field
                as={Checkbox}
                name='ingress_bw_limit'
                label='Ingress BW Limit'
              />
            </div>
            <div className='col-md-6 py1'>
              <Field
                as={Checkbox}
                name='ingress_pps_limit'
                label='Ingress PPS Limit'
              />
            </div>
          </div>
        </FormSection>
      }

      {!sku.trial && sku.platform && ['vcpu_pool'].includes(sku.platform) &&
        <div className='row'>
          <div className='col-md-12 py1'>
            <Checkbox
              label='Limit vCPU Count'
              onChange={handleVCPUPoolProcessorCheck}
            />
          </div>
        </div>
      }

      {sku.platform && ['vcpu_pool'].includes(sku.platform) && vcpupoolcount.isActive &&
        <Field as={Processors} name='number_of_vcpus' />
      }

      {!sku.trial && sku.platform && ['capacity_pool'].includes(sku.platform) &&
        <div className='row'>
          <div className='col-md-12 py1'>
            <Checkbox
              label='Limit on Number of ACOS Devices Sharing this FlexPool License'
              checked={flexpoolquanity.isActive}
              onChange={handleFlexpoolQuanityCheck}
            />
          </div>
        </div>
      }

      {!sku.trial && (sku.platform && !['agalaxy', 'cap_visibility', 'capacity_pool', 'vcpu_pool'].includes(sku.platform) || sku.platform && ['capacity_pool'].includes(sku.platform) && flexpoolquanity.isActive) && !sku.unlimited_devices &&
        <Field as={Quantity} name='number_of_appliances' />
      }

      {sku && !sku.trial && sku.modular &&
        <div className='row'>
          <div className='col-md-12 py1'>
            <Field
              as={Checkbox}
              name='number_of_appliances'
              label='Multiple Chassis License'
              checked={limitChassisQty}
              onChange={() => setLimitChassisQty(!limitChassisQty)}
            />
          </div>
        </div>
      }

      {sku && sku.modular && !sku.trial && limitChassisQty &&
        <div>
          <FormSection>
            <Field
              as={InputField}
              name='chassis_quantity'
              label='Chassis Quantity'
              id='license_chasis_quantity'
              type='number'
              min={2}
              max={26}
              customonchange={(v) => setChassisQty(v)}
            />
          </FormSection>
        </div>
      }

      {sku.platform === 'agalaxy' && !sku.trial &&
        <Field
          as={ManagedDevices}
          name='managed_devices'
        />
      }

      {sku && sku.platform === 'agalaxy' && !['agalaxy_tps', 'agalaxy_tps_trial'].includes(sku.name) &&
        <Field as={LegacyPartitions} name='number_of_partitions' />
      }

      {sku && ((sku.flexpool && modularFlexpool) || (sku.modular && !sku.flexpool)) && !sku.trial &&
        <div>
          <div className='row'>
            <div className='col-md-12 py1'>
              <Checkbox
                label='Limit ACOS Version'
                onChange={handleVersionRangeCheck}
                checked={version_range.isActive}
              />
            </div>
          </div>
        </div>
      }

      {sku && ((sku.flexpool && modularFlexpool) || (sku.modular && !sku.flexpool)) && !sku.trial && version_range.isActive &&
        <div>
          <Field as={Version} name='min_version' id='license_min_version' min="5.2" max="98.98" label='Minimum' step="0.01"/>
          <Field as={Version} name='max_version' id='license_max_version' min="5.21" max="98.99" label='Maximum' step="0.01"/>
        </div>
      }

      {sku && !sku.trial && sku.platform !== 'vcpu_pool' && sku.platform !== 'subscriber_pool' && (sku.flexpool || sku.modular || (sku.platform === 'thunder' && bandwidthSlider.isActive)) &&
        <FormSection>
          {(!sku.unlimited_bandwidth || sku.modular) &&
            <Field
              as={FlexPoolBandwidth}
              name='bandwidth'
              initialValue={attributes["bandwidth"]}
              showMbps={sku.platform != 'harmony'}
              chassisQty={limitChassisQty ? chassisQty : ''}
              showUnlimitedOption={canHaveUnlimitedBw(sku)}
            />
          }
          {sku.platform === 'capacity_pool' &&
            <AllocatedBandwidths />
          }
          {sku && sku.flexpool && sku.platform !== 'harmony' &&
            <Field as={CheckInFrequency} name='check_in_frequency' />
          }
          {sku.platform !== 'harmony' && sku.platform !== 'thunder' && !sku.modular &&
            <Field as={CthunderAutoDeactivate} name='cthunder_auto_deactivate' />
          }
        </FormSection>
      }

      {
        ((sku.flexpool && modularFlexpool) || (sku.modular && !sku.flexpool)) && !sku.trial && sku.platform !== 'subscriber_pool' &&
        <div>
          <FormSection>
            <Field as={RangeSlider} name='pps' title="Packets Per Second" unit="x 100k PPS" min={1} max={10000} />
          </FormSection>
        </div>
      }

      {sku.platform === 'capacity_pool' &&
        <FormSection>
          <Field as={Checkbox} name='match_cores_and_memory' label={(!sku.flexpool || (sku.flexpool && modularFlexpool))?'Set Fixed Activation Cores and Memory':'Set Fixed Activation Cores'} getChange={getMatchChange} />
          <br/><small className='form-text text-muted font-weight-normal'>
            {' '}By default, assigned cores and memory are set based on each activation's requested bandwidth.
          </small>
        </FormSection>
      }

      {
        sku.modular && !sku.trial && sku.platform !== 'vcpu_pool' && (sku.platform !== 'capacity_pool' || matchCoresAndMemory) && sku.platform !== 'subscriber_pool' &&
        <div>
          <FormSection>
            <Field as={RangeSlider} name='cpus' title="Maximum CPU Cores" unit="Cores" min={1} max={99}/>
          </FormSection>
          {(!sku.flexpool || (sku.flexpool && modularFlexpool)) &&
            <FormSection>
              <Field as={RangeSlider} name='memory' title="Maximum RAM" unit="GB" min={2} max={1024} />
            </FormSection>
          }
        </div>
      }

      {
        ((sku.flexpool && modularFlexpool) || (sku.modular && !sku.flexpool)) && !sku.trial &&
        <div>
          <Field as={SSLType} name='ssl_chipset_type' onSslChange={handleSslChipsetType}/>
        </div>
      }

      {((sku.flexpool && modularFlexpool) || (sku.modular && !sku.flexpool)) && !sku.trial && ssl_chipset_type_set.isActive &&
        <FormSection>
          <Field as={RangeSlider} name='ssl_chipset_count' title="SSL Chipset Limit" unit="Chip" min={1} max={99}/>
        </FormSection>
      }

      {((sku.flexpool && modularFlexpool) || (sku.modular && !sku.flexpool)) && !sku.trial &&
        <FormSection>
          <Field
            as={Checkbox}
            name='spe_present'
            label='SPE/Low-Latency Support'
          />
        </FormSection>
      }

      {((sku.flexpool && modularFlexpool) || (sku.modular && !sku.flexpool)) && !sku.trial &&
        <FormSection>
          <Field
            as={Checkbox}
            name='hardware_blocking'
            label='Hardware Blocking'
          />
        </FormSection>
      }

      {sku && ((sku.flexpool && modularFlexpool) || (sku.modular && !sku.flexpool)) && !sku.trial && skusForPortsConfig.indexOf(sku.platform) > -1 &&
        <div className='row'>
          <div className='col-md-12 py1'>
            <PortSelector port_array={props.port_array}/>
          </div>
        </div>
      }

      {sku && sku.platform === 'subscriber_pool' &&
        <FormSection>
          <Field as={RangeSliderLimited} name='subscriber_count' title="Subscriber Count" unit="Subscribers" min={1000} step={1000}
            max={1000000}/>
        </FormSection>
      }

      <Field as={Reason} name='reason' />

      <FormSection>
        <Field as={SfdcId} name='sfdc_partner_id' />
        <Field as={PartnerName} name='partner_name' />
        <Field as={OraclePart} name='oracle_part' />
        <Field as={OracleLine} name='oracle_line' />
        <Field as={CustomerPoNumber} name='bill_to_purchase_order_id' />
        <Field as={ServiceContractId} name='service_contract_id' />
        <Field
          as={SalesOrderSelect}
          isRequired={policies.isSalesOrderRequired}
          name='order_attributes.a10_sales_order_id'
        />
      </FormSection>

      {!threatstopTrialSku(sku) &&
        <Field as={StartsAt} name='starts_at' onSelect={handleStartChange} initialValue={attributes["starts_at"] ? getMoment(attributes["starts_at"]) : null}  />
      }

      {sku && !sku.cannot_expire &&
        <Field as={ExpiresAt} name='expires_at' isEdit={isEdit} sku={sku} startsAt={startsAt || initialStartsDate} reason={"n/a"} canSetNever={policies.SOA_oracle_support} initialValue={attributes["expires_at"] ? getMoment(attributes["expires_at"]) : null} />
      }

      {sku && sku.flexpool && !sku.cannot_expire &&
        <Field as={AutoRenew} name='auto_renew' />
      }

      {!isEdit && <Field
        as={AuthorizedUsers}
        name='authorized_users'
        required={!(org && org.owner_id)}
      />
      }

      {policies.changeShellAccess && sku && !sku.flexpool &&
        <Field as={ShellAccess} name='shell_access' />
      }

      {policies.changeShellAccess && sku && !sku.flexpool &&
        <Field as={ShellRootAccess} name='shell_root_access' />
      }

      {policies.changeShellAccess && sku && !sku.flexpool &&
        <Field as={CThunderPartner} name='c_thunder_partner' />
      }

      {policies.changeShellAccess && sku && !sku.flexpool &&
        <Field as={DiskEncryption} name='disk_encryption' />
      }

      <FormSection>
        <Field
          as={LicenseNotes}
          name='license_notes_attributes'
          initialVal={props.license_notes}
          allowInternal={policies.writeInternal}  />
      </FormSection>

      {sku && sku.flexpool && <FormSection>
        <Field
          as={Checkbox}
          name='enterprise'
          label='Enterprise'
        />
        <div>
          <small className='form-text text-muted font-weight-normal'>
            {' '}License requests for new activation or bandwidth changes need approval from authorized license admin.
            <br/>
            WARNING: once this option is turned on, license requests from Thunders with a
            valid token will NO LONGER be automatically granted.
            Please try the workflow before using this in production.
          </small>
        </div>
      </FormSection>
      }

      <FormActions>
        <FormSubmit value={`${action} License`} />
      </FormActions>
      <GLMToastContainer />
    </Form>
  )
}

export default LicenseForm
